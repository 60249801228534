body {
    background: #F5F5F5;
    min-width: 1125px;
}
.tracking{
    font-family:Helvetica;
}
.dashboard{
    display:flex;
}
.dashboard #dashboard_bar{
    width:50%;
    height:500px;
    padding:30px;
    background:#fff;
    border-radius: 8px;
}
.dashboard #dashboard_pie{
    width:50%;
    height:500px;
    padding:30px;
    background:#fff;
    margin-left:20px;
    border-radius: 8px;
}
.tm_alert {
    z-index: 999;
    width: 250px;
    height: 132px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
    position: fixed;
    top: 72px;
    right: 30px;
    padding: 18px 20px 8px 20px;
}

.te_alert_title {
    font-size: 18px;
    line-height: 22px;
    height: 22px;
    color: #333;
    font-weight: 600;
    font-family: Helvetica-Bold, Helvetica;
}

.te_alert_content {
    cursor: pointer;
    font-size: 14px;
    line-height: 41px;
    height: 41px;
    margin-top: 2px;
    color: #666;
    display: flex;
    justify-content: space-between;
}

.tm_alert_number {
    display: inline-block;
    padding: 0 7px;
    font-size: 10px;
    line-height: 13px;
    background: rgba(255, 183, 116, 1);
    border: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
}

.te_alert_content1 {
    cursor: pointer;
    font-size: 14px;
    line-height: 41px;
    height: 41px;
    color: #666;
    border-top: 1px solid #999;
    display: flex;
    justify-content: space-between;
}

.tm_alert_number2 {
    display: inline-block;
    padding: 0 7px;
    font-size: 10px;
    line-height: 13px;
    background: rgba(255, 77, 79, 1);
    border: 1px solid #fff;
    border-radius: 10px;
    color: #fff;
}

.tk_alert_icon {
    width: 12px;
    height: 12px;
    color: #666;
}

.track_alert {}

.tk_header {
    width: 100%;
    height: 72px;
    padding: 0 28px 0 30px;
    display: flex;
    background: #fff;
    justify-content: space-between;
}

.th_header_logo {
    font-size: 24px;
    color: rgba(51, 51, 51, 1);
    font-weight: 600;
    line-height: 29px;
    // margin-top: 22px;
    cursor: default;
    display: flex;
    align-items: center;
}

.th_header_logo img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    // margin-top: -2px;
    border-radius: 6px;

}

.tk_header_nav {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 18px;
    color: #666;
    font-family: Helvetica;
    // margin-top: 25px;
}

.tk_header_nav_nm {
    // line-height: 22px;
    margin: 0 24px;
    cursor: pointer;
}

.tk_header_notice {
    display: flex;
    align-items: center;
    .Instructions{
        font-size: 16px;
        margin-right: 36px;
        cursor: pointer;
        color: #666666;
    }
}

.tk_header_notice img {
    width: 30px;
    height: 31px;
    cursor: pointer;
}

.tk_content {
    padding: 24px 30px 30px 30px;
}

.plan_top {
    // height: 225px;
    background: #fff;
    border-radius: 8px;
    padding: 24px 20px 30px 21px;
}

.plan_top_title {
    font-size: 18px;
    color: #333;
    line-height: 22px;
    font-weight: 600;
    cursor: default;
}

.plan_top_title2 {
    margin-top: 24px;
    display: flex;
    // justify-content: space-between;
}

.plan_top_small {
    font-size: 14px;
    color: #666;
    line-height: 19px;
    margin-top: 16px;
    cursor: default;
}

.plan_top_progress {
    position: relative;
    width: 100%;
    height: 20px;
    background: #F9F5FF;
    border-radius: 10px;
    margin: 16px 0;
}

.plan_top_progress_bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 20px;
    background: #9C63F9;
    border-radius: 10px;
    opacity: .8;
}

.plan_bottom {
    margin-top: 20px;
    background: #fff;
    border-radius: 8px;
    padding: 24px 20px 30px 21px;
}

.plan_meal_list {}

.plan_meal_list .meal_li {
    display: flex;
    justify-content: space-between;
    height: 80px;
    border-radius: 6px;
    line-height: 80px;
    padding: 0 20px;
    margin-top: 12px;
    cursor: default;
}

.plan_meal_list .meal_li:first-child {
    margin-top: 16px;
}

.plan_meal_list .meal_li:nth-child(3n+1) {
    background: #EEE4FF;
    .li_button{
      background-color: #9C63F9;
    }
}

.plan_meal_list .meal_li:nth-child(3n+2) {
    background: rgba(255, 240, 200, 0.8);
    .li_button{
        background-color: #FDD05B;
      }
}

.plan_meal_list .meal_li:nth-child(3n) {
    background: rgba(244, 235, 220, 0.8);
    .li_button{
        background-color: #DDBB86;
      }
}

.plan_meal_list .meal_li .meal_li_left {
    font-size: 14px;
    color: #666;
}

.plan_meal_list .meal_li .meal_li_left .meal_li_left_price {
    font-size: 30px;
    color: #333;
    font-weight: 600;
}

.plan_meal_list .meal_li .li_button {
    width: 92px;
    height: 32px;
    margin-top: 24px;
    border-radius: 2px;
    padding: 0;
    border:none;
}
.ant-table-thead > tr > th{
    background:rgba(156, 99, 249, 0.1) ;
    height:54px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color:#333;
    font-weight: 600;
    font-size:16px;
}
.ant-tabs-ink-bar{
    background:#9C63F9;;
}
.ant-tabs{
    color:#999999;
}
.ant-table{
    color:#666;
}
.track_search{
    display:flex;
    justify-content: space-between;
    padding: 18px 20px;
    background:#fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.track_search .track_search_left .ant-btn{
    // background:#9C63F9;
    border:none;
    color:#fff;
}
.track_search_right .track_input .ant-input{
    width:299px;
    border:1px solid #d9d9d9;
}
.track_search .track_search_left .ant-picker{
    border:1px solid #d9d9d9
}
.customize{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
}
.customize .customize_left{
    width:39%;
    background:#fff;
    padding-bottom: 30px;
    border-radius: 8px;
}
.customize .customize_right{
    width:61%;
    margin-left:20px;
    padding-bottom: 30px;
    background:#fff;
    border-radius: 8px;
}
.customize_header{
    cursor: default;
    font-size:18px;
    font-weight: 600;
    color:#333;
    line-height:68px;
    height:68px;
    padding:0 20px;
}
.left_content {
    padding: 0 20px;
}
.left_content .left_content_li{

}
.left_content .left_content_li .color_input{
    width:220px;
}
.bar_color{
    margin-top:12px;
    font-size:16px;
    line-height:43px;
    color:#333;
    cursor: default;
}
.li_first{
    margin-top:-20px;
}
.left_width{
    width:220px;
    border:1px solid #666;
}
.left_content_li2{
    margin-top:24px;
    font-size: 12px;
}
.left_content_box{
    display:flex;
    justify-content: space-between;
}
.left_content_li2 .left_content_check{
  
    color:#333;
    line-height:22px;
    font-size: 12px;
    margin-bottom: 6px;
}
.left_content_li2 .ant-radio-wrapper{
    font-size: 12px;
    color:#333;
}
.content_box_left{
    width:50%;
   
}
.content_box_right{
    width:50%;
    margin-left:20px;
}
.left_content_box .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border:1px solid #666;
}
.left_content_li .bar_color1{
    font-size:14px;
    color:#333;
    line-height:14px;
    margin-top:30px;
    cursor: default;
}
.left_content_option{

}
.left_content_option .left_content_top{
    font-size: 12px;
    line-height:17px;
    margin-top:12px;
    color:#666;
    cursor: default;
}
.left_content_option .left_content_bottom{
    font-size:12px;
    line-height:17px;
    margin-top:8px;
    color:rgba(156, 99, 249, 0.68);
    cursor: pointer;
}
.customize_content{
    padding:0 20px;
}
.img_header{
    display:flex;
    justify-content: space-between;
    margin-top:5px;
}
.content_address{
    font-size: 14px;
    color:#333;
    line-height:17px;
    cursor: default;
}
.content_date{
    font-size: 12px;
    color:#999;
    line-height: 14px;
    cursor: default;
    span{
        width: 60px;
        text-align: center;
    }
}
.content_flex{
    margin:18px 17px;
    width:100%;
    display:flex;
    justify-content: space-between;
    position: relative;
}
.content_yuan{
    z-index: 2;
    width:16px;
    height:16px;
    border-radius: 50%;
    background-color: #9C63F9;
}
.content_back{
    position: absolute;
    top:4px;
    left: 10px;
    width:98%;
    height:8px;
    background-color: #9C63F9;
    opacity: 0.6;
}
.content_status{
    padding: 0 20px;
    margin:30px 0 16px 0;
    color:#333;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    cursor: default;
}
.content_map{
    padding:0 20px;
}
.content_map img{
    width:100%;
}
.content_schedule{
    padding:20px 20px 0;
    position: relative;
    >ul{
        margin: 0;
        padding: 0;
        >li{
            display: flex;
            align-items: center;
            font-size: 12px;
            cursor: default;
            &:first-child{
                margin-bottom: 21px;
            }
            span{
                z-index: 2;
                display: flex;
                overflow: hidden;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border:1px solid rgba(151, 151, 151, 0.5);
                margin-right: 12px;
            }
            img{
                width: 16px;
                height: 16px;
                border-radius: 50%;
                z-index: 2;
                margin-right: 12px;
            }
            p:first-of-type{
                color: #999999;
            }
        }
    }
    .line{
        position: absolute;
        background-color: #CCCCCC;
        height: 38px;
        width: 1px;
        top: 47px;
        left: 27px;
    }
}
.customize_status_bar{
    display: flex;
    width: 100%;
    height: 72px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;
    .customize_status_bar_left_span{
        font-size: 18px;
        font-weight: bold;
        // color: #999999;
    }
}
.customize_main{
    margin-top: 24px;
    display: flex;
    width: 100%;
}
.track_search_loag{
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}
.operation_demonstration{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255,.5);
    z-index: 99;
    justify-content: center;
    padding-top:10% ;
    display: flex;
    justify-content: center;
}
.operation_demonstration_main{
    font-size: 30px;
    height: 50%;
    width: 50%;
    text-align: right;
}
.content_car{
    width: 16px;
    height: 16px;
    display: flex;
    background-color: #fff;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid rgba(151, 151, 151, 0.5);
    box-sizing: border-box;
    margin-right: 12px;
}
#data th.ant-table-cell{
    // text-align: center;
    font-weight: 600;
}
#data{
    // text-align: left;
    overflow: hidden;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
#data tr th:first-child {
    width: 400px;
}
.InstructionsModal{
    .msg{
        p{
            font-weight: 700;
            margin-top: 20px;
            &:first-child{
              margin-top: 0;
            }
          }
          span{
              display: block;
          }
    }
    
  }