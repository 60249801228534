.tabs{
  display: flex;
  margin-bottom: 12px;
  background: #dfe3e8;
  >ul{
    display: flex;
    >li{
      padding: 0 12px;
      height: 50px;
      line-height: 50px;
      cursor: pointer;
    }
    .active{
      border-bottom: 2px solid #5c6ac4;
    }
  }
}


.tables{
  table{
    cursor: default;
  }
}